import { ClearState } from '@WebUi/app/store/app.actions';
import { AppStateModel } from '@WebUi/app/models/app-state.model';
import { isSupportedLang, Lang, Locale, LOCALES, SUPPORTED_LANGS } from '@Libs/model';
import { ActionsWatcherService } from '@WebUi/app/services/actions-watcher.service';
import { AppState } from '@WebUi/app/store/app.state';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SetLang, StoreAppVersion } from '@WebUi/app/store/app.actions';
import { firstValueFrom } from 'rxjs';
import packageInfo from 'package.json';
import Rollbar from 'rollbar';
import { DateAdapter } from '@angular/material/core';

export function prefersReducedMotion(): boolean {
  const mediaQueryList: MediaQueryList = window && window.matchMedia('(prefers-reduced-motion)');

  return mediaQueryList.matches;
}

export function stateActualizerBeforeInitFactory(
  store: Store,
  rollbarService: Rollbar,
): () => Promise<any> {
  return async (): Promise<any> => {
    const appState: AppStateModel = store.selectSnapshot(AppState);

    if (packageInfo.stateVersion !== appState.stateVersion) {
      await firstValueFrom(store.dispatch([new ClearState()]));
    } else if (packageInfo.version !== appState.appVersion) {
      await firstValueFrom(store.dispatch([new StoreAppVersion(packageInfo.version)]));
    }

    rollbarService.configure({
      payload: {
        appVersion: packageInfo.version,
        stateVersion: packageInfo.stateVersion,
      },
    });
  };
}

export function langInitializerFactory(
  translateService: TranslateService,
  dateAdapter: DateAdapter<Date>,
  store: Store,
  document: Document,
): () => Promise<any> {
  return async (): Promise<any> => {
    // Get 'lang' param from query string and store it in the app state
    const queryParams: URLSearchParams = new URLSearchParams(document.location.search);

    if (queryParams.has('lang')) {
      const newLang: string = queryParams.get('lang')!;

      if (isSupportedLang(newLang)) {
        await firstValueFrom(store.dispatch([new SetLang(newLang)]));
      }
    }

    // Register available languages
    translateService.addLangs(SUPPORTED_LANGS);

    const lang: Lang = store.selectSnapshot(AppState.lang);

    setDateAdapterLocale(dateAdapter, lang);

    // Set locale for Material Angular on lang change
    translateService.onLangChange
      .subscribe((event: LangChangeEvent) => {
        if (!isSupportedLang(event.lang)) {
          return;
        }

        setDateAdapterLocale(dateAdapter, event.lang);
      });

    // Apply language
    await firstValueFrom(translateService.use(lang))
      .then(() => {
        document.documentElement.lang = lang;
      });
  };
}

function setDateAdapterLocale(dateAdapter: DateAdapter<Date>, lang: Lang): void {
  const locale: Locale | undefined = LOCALES.get(lang);

  if (!locale) {
    return;
  }

  // Date adapter has .format method where there is hardcoded date format for inputs (PO approved this)
  dateAdapter.setLocale(locale);
}

export function servicesInitializerFactory(
  actionsWatcherService: ActionsWatcherService,
): () => Promise<any> {
  return async (): Promise<any> => {
    // Init global services
    actionsWatcherService.init();
  };
}
