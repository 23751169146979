import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  InteractionStatus,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { environment } from '@WebUi/env';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  AUTHORITY_FOR_ONBOARDING,
  Claims,
  DEFAULT_AUTHORITY,
  GODS_DOMAIN,
  GODS_GROUP,
  USER_FLOW_FOR_ONBOARDING,
  USER_FLOW_FOR_SU,
  UserFlowsConfig,
} from '@WebUi/app/models/app.model';
import { Lang, LOCALES, UnimicroPlatformDomain, isUnimicroPlatformDomain } from '@Libs/model';
import { AppService } from '@WebUi/app/services/app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly stopSilentTokenAcquiring$$: Subject<void> = new Subject<void>();
  readonly stopSilentTokenAcquiring$: Observable<void> = this.stopSilentTokenAcquiring$$.asObservable();

  constructor(
    private appService: AppService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) { }

  isAuthenticated(): boolean {
    return this.msalService.instance.getActiveAccount() !== null;
  }

  isAuthenticatedAsSuperUser(): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }

    const claims: Claims | null = this.getActiveAccountClaims();

    if (!claims || !claims.acr || !claims.groups || !claims.originalIdp) {
      return false;
    }

    if (claims.originalIdp !== GODS_DOMAIN) {
      return false;
    }

    if (claims.acr.toLowerCase() !== USER_FLOW_FOR_SU.name.toLowerCase()) {
      return false;
    }

    if (!claims.groups.includes(GODS_GROUP)) {
      return false;
    }

    return true;
  }

  isAuthenticatedForOnboarding(): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }

    const claims: Claims | null = this.getActiveAccountClaims();

    if (!claims || !claims.acr || !claims.originalIdp) {
      return false;
    }

    if (!isUnimicroPlatformDomain(claims.originalIdp)) {
      return false;
    }

    if (claims.acr.toLowerCase() !== USER_FLOW_FOR_ONBOARDING.name.toLowerCase()) {
      return false;
    }

    if (!claims.idp_access_token) {
      return false;
    }

    const originalClaims: any = this.extractAccessTokenPayload(claims.idp_access_token);

    if (!originalClaims.AppFramework) {
      return false;
    }

    if (!originalClaims.Elsa) {
      return false;
    }

    return true;
  }

  isAuthenticatedWithUnimicroPlatformDomain(unimicroPlatformDomain: UnimicroPlatformDomain): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }

    const claims: Claims | null = this.getActiveAccountClaims();

    if (!claims || !claims.acr || !claims.originalIdp) {
      return false;
    }

    if (!isUnimicroPlatformDomain(claims.originalIdp)) {
      return false;
    }

    return claims.originalIdp === unimicroPlatformDomain;
  }

  getActiveAccountClaims(): Claims | null {
    const accountInfo: AccountInfo | null = this.msalService.instance.getActiveAccount();

    if (!accountInfo || !accountInfo.idTokenClaims) {
      return null;
    }

    return accountInfo.idTokenClaims as Claims;
  }

  getActiveAccountUnimicroPlatformDomain(): UnimicroPlatformDomain | undefined {
    const claims: Claims | null = this.getActiveAccountClaims();

    if (!claims) {
      return undefined;
    }

    return isUnimicroPlatformDomain(claims.originalIdp) ? claims.originalIdp : undefined;
  }

  getActiveAccountAuthority(): string | undefined {
    const claims: Claims | null = this.getActiveAccountClaims();

    if (!claims || !claims.acr) {
      return;
    }

    const userFlows: UserFlowsConfig = environment.azure.userFlows as UserFlowsConfig;
    const acr: string = claims.acr.toLowerCase();
    const userFlowKey: string | undefined = Object.keys(userFlows).find((userFlowKey) => userFlows[userFlowKey].name.toLowerCase() === acr.toLowerCase());

    if (!userFlowKey || !userFlows[userFlowKey]) {
      return;
    }

    return userFlows[userFlowKey].authority;
  }

  getIdpAccessToken$(): Observable<string> {
    return this.acquireTokenSilent()
      .pipe(
        map((result: AuthenticationResult) => {
          if (!result) {
            throw new Error('There is no original access token');
          }

          const idpAccessToken: string | undefined = (result.idTokenClaims as any).idp_access_token;

          if (!idpAccessToken) {
            throw new Error('There is no original access token');
          }

          return idpAccessToken;
        }),
      );
  }

  extractAccessTokenPayload(accessToken: string): any {
    const base64Payload: string | undefined = accessToken.split('.')[1];

    if (!base64Payload) {
      throw new Error('Invalid token');
    }

    return JSON.parse(atob(base64Payload));
  }

  isAccessTokenExpired(accessToken: string): boolean {
    const claims: any = this.extractAccessTokenPayload(accessToken);

    if (!claims.exp) {
      throw new Error('Invalid token');
    }

    const expireAt: number = claims.exp * 1000;

    return Date.now() >= expireAt;
  }

  acquireTokenSilent(silentRequest?: Partial<SilentRequest>): Observable<AuthenticationResult> {
    return this.msalService.acquireTokenSilent({
      ...silentRequest,
      account: silentRequest?.account ?? this.msalService.instance.getActiveAccount() ?? undefined,
      authority: silentRequest?.authority ?? this.getActiveAccountAuthority() ?? DEFAULT_AUTHORITY,
      scopes: silentRequest?.scopes ?? ['openid', 'profile', environment.azure.clientId],
      redirectUri: '/silent-auth',
    })
      .pipe(
        takeUntil(this.stopSilentTokenAcquiring$),
        catchError((error: Error) => {
          this.stopSilentTokenAcquiring$$.next();

          return throwError(() => error);
        }),
      );
  }

  login(
    redirectRequest: Partial<Omit<RedirectRequest, 'scopes'>> & { authority: string },
  ): Observable<void> {
    return this.appService.lang$
      .pipe(
        take(1),
        switchMap((lang: Lang) => {
          return this.msalBroadcastService.inProgress$
            .pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None),
              take(1),
              switchMap(() => this.msalService.loginRedirect({
                ...redirectRequest,
                scopes: ['openid', 'profile', environment.azure.clientId],
                extraQueryParameters: {
                  ...redirectRequest.extraQueryParameters,
                  ui_locales: LOCALES.get(lang)!,
                },
              })),
            );
        }),
      );
  }

  onboardingLogin(redirectRequest?: Partial<Pick<RedirectRequest, 'domainHint' | 'redirectStartPage' | 'state'>>): Observable<false> {
    return this.login({
      authority: AUTHORITY_FOR_ONBOARDING,
      prompt: 'login',
      domainHint: redirectRequest?.domainHint,
      redirectStartPage: redirectRequest?.redirectStartPage,
      state: redirectRequest?.state,
      extraQueryParameters: redirectRequest?.domainHint === UnimicroPlatformDomain.TEST_UNIMICRO ? {
        'mode': 'dev',
      } : undefined,
    })
      .pipe(
        map(() => false),
      );
  }

  logout(endSessionRequest?: Partial<EndSessionRequest>): Observable<void> {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        take(1),
        switchMap(() => this.msalService.logoutRedirect(endSessionRequest)),
      );
  }

}
