declare const navigator: Navigator;

export enum Lang {
  EN = 'en', // English (International)
  NO = 'no', // Norwegian (Bokmal)
}

export const SUPPORTED_LANGS: Lang[] = [
  Lang.EN,
  Lang.NO,
];

export enum Locale {
  EN = 'en',
  NO = 'nb-NO',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isSupportedLang(lang: any): lang is Lang {
  return SUPPORTED_LANGS.includes(lang);
}

export const FALLBACK_APP_LANGUAGE: Lang = Lang.EN;

export const NAVIGATOR_LANGUAGE: string = navigator.language.split('-')[0];

export const DEFAULT_APP_LANGUAGE: Lang = isSupportedLang(NAVIGATOR_LANGUAGE) ? NAVIGATOR_LANGUAGE : Lang.NO;

export const LOCALES: Map<Lang, Locale> = new Map<Lang, Locale>();
LOCALES.set(Lang.EN, Locale.EN);
LOCALES.set(Lang.NO, Locale.NO);

export const LOCALE_NAMES: Map<Lang, string> = new Map<Lang, string>();
LOCALE_NAMES.set(Lang.EN, 'English');
LOCALE_NAMES.set(Lang.NO, 'Bokmål');
